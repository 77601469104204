<template>
	<a-drawer width="50%" title="合同详情" placement="right" :maskClosable="false" :visible="visible" @close="onClose">
		<a-table :columns="columns" :loading="tableLoading" :data-source="list" class="table" rowKey="flowId">
			<span slot="operation" slot-scope="text, record">
				<a :href="fileBaseUrl + record.url" target="_blank">下载原文件</a>
				<a-divider type="vertical" v-if="record.status == 1"></a-divider>
				<a v-if="record.status == 1" :href="fileBaseUrl + record.testingUrl" target="_blank">下载审读文件</a>
			</span>
		</a-table>
	</a-drawer>
</template>
<script>
export default {
	props: ['visible', 'closeModal', 'oderId'],
	data() {
		const _this = this;
		return {
			list: [],
			tableLoading: false,
			fileBaseUrl: process.env.VUE_APP_RESOURCES_HOST,
			columns: [
				{
					title: '文件类型',
					dataIndex: 'type',
					key: 'type',
					customRender(text) {
						return text == 1 ? 'WORD' : text == 2 ? 'PDF' : '--';
					},
				},
				{
					title: '行业',
					dataIndex: 'industryName',
					key: 'industryName',
				},
				{
					title: '检测状态',
					dataIndex: 'status',
					key: 'status',
					customRender(text) {
						return text == 0 ? '未开始' : text == 1 ? '检测完成' : text == 2 ? '检测中' : text == 3 ? '检测失败' : '';
					},
				},
				{
					title: '提交时间',
					dataIndex: 'addTime',
					key: 'addTime',
					customRender(text) {
						return _this.$moment(text).format('YYYY-MM-DD HH:mm:ss');
					},
				},
				{ title: '操作', key: 'operation', scopedSlots: { customRender: 'operation' } },
			],
		};
	},
	watch: {
		visible(data) {
			if (data) {
				this.getContractList();
			}
		},
	},
	methods: {
		getContractList() {
			let params = {
				tradeId: this.oderId,
			};
			const _this = this;
			this.$http
				.post('/resource/contractfile/queryList', params)
				.then(res => {
					_this.list = res.data.dataList;
				})
				.catch(err => {
					console.log(err);
				});
		},
		onClose() {
			this.closeModal();
		},
	},
};
</script>
<style lang="less" scoped>
.ant-divider {
	margin: 0 8px;
}
</style>
