<template>
	<div class="pageContainer">
		<a-row class="checkOrder">
			<a-form-model style="position: relative; top: -5px;" layout="inline" ref="ruleForm" :model="formInline" @submit="handleSubmit" @submit.native.prevent>
				<a-form-model-item ref="time" prop="time">
					<a-range-picker v-model="formInline.time" />
				</a-form-model-item>
				<a-form-model-item ref="state" prop="state">
					<a-select v-model="formInline.state" style="width: 150px" placeholder="订单支付状态" allowClear>
						<a-select-option value="0">
							待支付
						</a-select-option>
						<a-select-option value="1">
							已支付
						</a-select-option>
						<a-select-option value="3">
							已退款
						</a-select-option>
						<a-select-option value="6">
							支付失败
						</a-select-option>
						<a-select-option value="9">
							退款失败
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item ref="type" prop="type">
					<a-select v-model="formInline.type" style="width: 150px" placeholder="支付方式" allowClear>
						<a-select-option value="2">
							支付宝支付
						</a-select-option>
						<a-select-option value="1">
							微信支付
						</a-select-option>
						<a-select-option value="3">
							会员
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item ref="status" prop="status">
					<a-select v-model="formInline.status" style="width: 150px" placeholder="检测状态" allowClear>
						<a-select-option value="0">
							未开始
						</a-select-option>
						<a-select-option value="1">
							检测完成
						</a-select-option>
						<a-select-option value="2">
							检测中
						</a-select-option>
						<a-select-option value="3">
							检测失败
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item class="search">
					<a-dropdown-button type="primary" html-type="submit">
						<a-icon type="search" />
						查询
						<a-menu slot="overlay">
							<a-menu-item key="1" @click="reset">重置查询选项</a-menu-item>
						</a-menu>
					</a-dropdown-button>
				</a-form-model-item>
			</a-form-model>

			<a-table :columns="columns" :loading="tableLoading" :data-source="data" class="table" :pagination="pagination" @change="tableChange" :scroll="{ y: tableHeight }" rowKey="tradeId">
				<a-tag
					slot="payState"
					slot-scope="text"
					:color="
						text == 0
							? '#F89900'
							: text == 1
							? '#52c41a'
							: text == 2
							? '#f5222d'
							: text == 3
							? '#13c2c2'
							: text == 4
							? ' #722ed1'
							: text == 6
							? ' #f5222d'
							: text == 7
							? ' #F89900'
							: text == 9
							? ' #f5222d'
							: ''
					"
				>
					{{
						text == 0
							? '待支付'
							: text == 1
							? '已支付'
							: text == 2
							? '支付中'
							: text == 3
							? '已退款'
							: text == 4
							? '退款中'
							: text == 6
							? '支付失败'
							: text == 7
							? '支付取消'
							: text == 9
							? '退款失败'
							: '--'
					}}
				</a-tag>
				<span slot="contractTrade" slot-scope="text">
					<a>{{ text }}</a>
				</span>
				<span slot="operation" slot-scope="text, record">
					<a v-if="record.state == 0 || record.state == 6" @click="pay(record)">继续支付</a>
					<a-divider type="vertical" v-if="record.state == 0 || record.state == 6"></a-divider>
					<a-popconfirm v-if="record.state == 0 || record.state == 6 || record.state == 7" placement="topLeft" ok-text="确定" cancel-text="取消" @confirm="cancelOrder(record.contractTrade)">
						<template slot="title">
							<p>确定取消订单吗？</p>
						</template>
						<a>取消订单</a>
					</a-popconfirm>
					<a-divider type="vertical" v-if="record.state == 0 || record.state == 6 || record.state == 7"></a-divider>
					<a @click="queryDetail(record)">查看详情</a>
				</span>
			</a-table>
		</a-row>
		<PayModal :payData="payData" :payModalVisible="payModalVisible" :tradeId="tradeId" :closePayModal="closePayModal" />
		<DetailModal :visible="contractDetailVisible" :closeModal="closeContractDetail" :oderId="contractTradeId" />
	</div>
</template>
<script>
import PayModal from '../CheckContract/components/PayModal.vue';
import DetailModal from './components/contractDetailModal.vue';
export default {
	components: {
		PayModal,
		DetailModal,
	},
	data() {
		const _this = this;
		return {
			contractDetailVisible: false,
			contractTradeId: '',
			formInline: {
				time: [],
				state: undefined,
				status: undefined,
				type: undefined,
			},
			columns: [
				{
					title: '订单编号',
					dataIndex: 'contractTrade',
					key: 'contractTrade',
					width: '22%',
					scopedSlots: { customRender: 'contractTrade' },
				},
				{
					title: '总金额（元）',
					dataIndex: 'price',
					key: 'price',
					width: '10%',
					customRender(text) {
						return text / 1000;
					},
				},
				{
					title: '优惠金额（元）',
					dataIndex: 'discountAmount',
					key: 'discountAmount',
					width: '11%',
					customRender(text) {
						return text / 1000;
					},
				},
				{
					title: '实付金额（元）',
					dataIndex: 'amount',
					key: 'amount',
					width: '11%',
					customRender(text) {
						return text / 1000;
					},
				},
				{
					title: '提交时间',
					dataIndex: 'addTime',
					key: 'addTime',
					width: '13%',
					customRender(text) {
						return _this.$moment(text).format('YYYY-MM-DD HH:mm:ss');
					},
				},
				{
					title: '支付方式',
					dataIndex: 'type',
					key: 'type',
					width: '11%',
					customRender(text) {
						return text == 1 ? '微信支付' : text == 2 ? '支付宝支付' : text == 3 ? '会员免费' : '--';
					},
				},
				{
					title: '检测状态',
					dataIndex: 'status',
					key: 'status',
					width: '10%',
					customRender(text) {
						return text == 0 ? '未开始' : text == 1 ? '检测完成' : text == 2 ? '检测中' : text == 3 ? '检测失败' : '';
					},
				},
				{
					title: '支付状态',
					dataIndex: 'state',
					key: 'state',
					width: '10%',
					scopedSlots: { customRender: 'payState' },
				},
				{ title: '操作', width: '10%', key: 'operation', width: 250, scopedSlots: { customRender: 'operation' } },
			],
			data: [],
			pagination: {
				total: 0,
				current: 0,
				defaultPageSize: 10,
				showTotal: (total, range) => `当前第 ${range[0]}-${range[1]} 条 共计 ${total} 条`,
				showSizeChanger: true,
				pageSizeOptions: ['5', '10', '15', '20'],
				onShowSizeChange: (current, pageSize) => {
					this.pageSize = pageSize;
				},
			},
			payData: {},
			payModalVisible: false,
			tradeId: null, // 主订单号
			tableLoading: true,
			pageSize: 10,
			userInfo: {},
			tableHeight: 450,
		};
	},
	created() {
		this.userInfo = this.$store.getters.userInfo;
		this.handleSubmit('placeholder', 0, 10);

		if (document.body.clientWidth <= 1366) {
			this.tableHeight = 390;
		} else if (document.body.clientWidth > 1366 && document.body.clientWidth <= 1440) {
			this.tableHeight = 500;
		} else if (document.body.clientWidth > 1440 && document.body.clientWidth <= 1600) {
			this.tableHeight = 530;
		} else if (document.body.clientWidth > 1600) {
			this.tableHeight = 650;
		}
	},
	methods: {
		closeContractDetail() {
			this.contractDetailVisible = false;
		},
		queryDetail(data) {
			this.contractDetailVisible = true;
			this.contractTradeId = data.contractTrade;
		},
		cancelOrder(id) {
			let _this = this;
			this.$http
				.get('/resource/contracttrade/delete?contractTrade=' + id)
				.then(res => {
					_this.$message.success('操作成功！');
					_this.handleSubmit('placeholder', this.pagination.current, this.pageSize ? this.pageSize : 10);
				})
				.catch(err => {
					console.log('取消订单失败', err);
				});
		},
		closePayModal() {
			this.payModalVisible = false;
			this.handleSubmit('placeholder', this.pagination.current, this.pageSize ? this.pageSize : 10);
		},
		pay(e) {
			this.tradeId = e.contractTrade;
			this.payData.amount = e.amount ? e.amount : 0;
			this.payModalVisible = true;
		},
		tableChange(e) {
			this.handleSubmit('placeholder', e.current - 1, e.pageSize);
			this.pagination.current = e.current;
		},
		handleSubmit(e, pageNum, pageSize) {
			let that = this;
			//查询
			let time = this.formInline.time;
			let params = {
				pageNum: pageNum || pageNum == 0 ? pageNum : 0,
				pageSize: pageSize ? pageSize : that.pageSize,
				buyUserCode: this.userInfo.userCode,
			};
			if (time && time.length > 0) {
				let minAddTime = that.$moment(time[0]).format('YYYY-MM-DD') + 'T00:00:00';
				let maxAddTime = that.$moment(time[1]).format('YYYY-MM-DD') + 'T23:59:59';
				params.minAddTime = minAddTime;
				params.maxAddTime = maxAddTime;
			}
			if (this.formInline.type) {
				params.type = Number(this.formInline.type);
			}
			if (this.formInline.state) {
				params.state = Number(this.formInline.state);
			}
			if (this.formInline.status) {
				params.status = Number(this.formInline.status);
			}
			that.tableLoading = true;
			this.$http
				.post('/resource/contracttrade/queryPageList', params)
				.then(res => {
					if (res.rescode == 200) {
						that.tableLoading = false;
						this.data = res.data.dataList;
						this.pagination.total = res.data.totalCount;
					} else {
						that.tableLoading = false;
						this.$message.warning(res.msg);
					}
				})
				.catch(error => {
					that.tableLoading = false;
					console.log(error);
				});
		},
		reset() {
			this.$refs.ruleForm.resetFields();
			this.handleSubmit('placeholder', 0, this.pageSize);
			this.pagination.current = 0;
		},
		orderDetail(data) {
			this.$router.push({
				name: 'OrderDetail',
				query: { tradeId: data.tradeId },
			});
		},
	},
};
</script>
<style lang="less" scoped>
.pageContainer {
	.ant-divider {
		margin: 0 8px;
	}
	.checkOrder {
		padding: 16px;
		height: 100%;
		background: #fff;
		overflow: auto;
	}
	.ant-form {
		// height: 7.3%;
	}
	/deep/.ant-table-row-expand-icon {
		display: none;
	}
	/deep/.ant-table-row-expand-icon-cell {
		border: 0;
	}
	.button {
		margin-top: 3px;
		margin-right: 20px;
		color: #999999;
	}
	.search {
		float: right;
	}
	.table {
		height: 95%;
		.innerTable {
			/deep/ .ant-table-row-level-0 {
				background: unset;
				// td {
				//     display: flex;
				//     align-items: center;
				//     justify-content: center;
				// }
			}
		}
		/deep/ .ant-table-row-level-0 {
			background: #edf3ff;
		}
		.img {
			width: 70px;
			height: 40px;
		}
		.icon {
			font-size: 25px;
			cursor: pointer; //鼠标变小手
		}
		.video {
			position: relative;
			.img {
				width: 70px;
				height: 40px;
			}
			.icon {
				font-size: 25px;
				cursor: pointer; //鼠标变小手
				position: absolute;
				top: -16px;
				left: 22px;
				color: #fff;
			}
		}
	}
	.marginR {
		margin-right: 20px;
	}
	.orange {
		background-color: #ff9e02;
		color: #fff;
	}
	.gray {
		background-color: #cccccc;
		color: #fff;
	}
	.tableFooter {
		.left {
			margin-top: 20px;
		}
		.floatR {
			float: right;
		}
	}

	.blue {
		color: #40a9ff;
	}
	.red {
		color: red;
	}
}
</style>
