<template>
	<div class="pageContainer">
		<a-row class="checkOrder">
			<a-form-model style="position: relative; top: -5px;" layout="inline" ref="ruleForm" :model="formInline" @submit="handleSubmit" @submit.native.prevent>
				<a-form-model-item ref="time" prop="time">
					<a-range-picker v-model="formInline.time" />
				</a-form-model-item>
				<a-form-model-item ref="payState" prop="payState">
					<a-select v-model="formInline.payState" style="width: 150px" placeholder="订单支付状态" allowClear>
						<a-select-option value="0">
							待支付
						</a-select-option>
						<a-select-option value="1">
							已支付
						</a-select-option>
						<a-select-option value="2">
							已关闭
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item ref="type" prop="type">
					<a-select v-model="formInline.type" style="width: 150px" placeholder="订单检测方式" allowClear>
						<a-select-option value="1">
							智能检测
						</a-select-option>
						<a-select-option value="2">
							人工检测
						</a-select-option>
						<a-select-option value="3">
							专家检测
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item ref="pay" prop="payType">
					<a-select v-model="formInline.payType" style="width: 150px" placeholder="支付方式" allowClear>
						<a-select-option value="1">
							支付宝支付
						</a-select-option>
						<a-select-option value="2">
							微信支付
						</a-select-option>
						<a-select-option value="3">
							会员
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item class="search">
					<a-dropdown-button type="primary" html-type="submit">
						<a-icon type="search" />
						查询
						<a-menu slot="overlay">
							<a-menu-item key="1" @click="reset">重置查询选项</a-menu-item>
						</a-menu>
					</a-dropdown-button>
				</a-form-model-item>
			</a-form-model>

			<a-table
				:columns="columns"
				:loading="tableLoading"
				:data-source="data"
				class="table"
				:pagination="pagination"
				@change="tableChange"
				:expandedRowKeys="expandedRowKeys"
				:defaultExpandAllRows="true"
				:scroll="{ y: tableHeight }"
				rowKey="tradeId"
			>
				<a-tag slot="payState" slot-scope="text" :color="text == 0 ? '#F89900' : text == 1 ? '#52c41a' : text == 2 ? '#f5222d' : text == 3 ? '#13c2c2' : text == 4 ? ' #722ed1' : ''">
					{{ text == 0 ? '待支付' : text == 1 ? '已支付' : text == 2 ? '支付失败' : text == 3 ? '已退款' : text == 4 ? '部分退款' : '--' }}
				</a-tag>
				<a-table slot="expandedRowRender" class="innerTable" :columns="innerColumns" slot-scope="record" :data-source="record.monitorOrdervOList" :pagination="false" rowKey="orderId">
					<span slot="content" slot-scope="text, record" @click="preview(record)">
						<img v-if="record.contentType == 1" :src="text" class="img" />
						<span class="video" v-else-if="record.contentType == 2 || record.contentType == 3">
							<img v-if="record.contentType == 2" :src="coverImgUrl(record.coverImgUrl)" class="img" />
							<a-icon type="play-circle" class="icon" />
							<img v-if="record.contentType == 3" src="https://resources.xbov.cn/img/hxznggfwpt/audioCodeImg.jpg" class="img" />
						</span>
						<span v-else-if="record.contentType == 4">{{ text && text.length > 8 ? text.substring(0, 8) + '...' : text ? text : '--' }}</span>
					</span>
					<span slot="operation" slot-scope="text, record" class="table-operation">
						<a @click="preview(record)">查看内容</a>
					</span>
					<template slot="footer">
						<div class="tableFooter">
							<span class="left">
								<span class="marginR">
									付款信息：折扣减免
									<span class="blue">￥{{ record.discountAmount || 0 }}</span>
								</span>
								<span class="marginR">
									优惠券减免
									<span class="blue">￥{{ record.couponAmount || 0 }}</span>
								</span>
								<span class="marginR">
									总实付金额：
									<span class="red">￥{{ record.payAmount || 0 }}</span>
								</span>
							</span>

							<span class="floatR">
								<a-button class="marginR" type="primary" @click="orderDetail(record)">查看详情</a-button>
								<a-button class="marginR gray" v-if="record.payState == 0" @click="cancelOrder(record)">取消订单</a-button>
								<a-button class="marginR orange" v-if="record.payState == 0" @click="pay(record)">立即支付</a-button>
							</span>
						</div>
					</template>
				</a-table>
			</a-table>
		</a-row>
		<Vviewer ref="viewer"></Vviewer>
		<MediaViewer :visible="mediaVisible" :mediaSrc="mediaSrc" :mediaType="mediaType" :closeMediaModal="closeMediaModal" />
		<TextViewer :textVisible="textVisible" :contentText="contentText" :closeTextModal="closeTextModal" />
		<PayModal :payData="payData" :payModalVisible="payModalVisible" :tradeId="tradeId" :closePayModal="closePayModal" />
	</div>
</template>
<script>
import Vviewer from '../../components/Vviewer';
import MediaViewer from '../../components/MediaViewer';
import TextViewer from '../../components/TextViewer';
import PayModal from '../../components/PayModal';

export default {
	components: {
		Vviewer,
		MediaViewer,
		TextViewer,
		PayModal,
	},
	data() {
		const _this = this;
		return {
			mediaSrc: null,
			mediaType: null,
			mediaVisible: false,
			textVisible: false,
			contentText: '',
			host: '',
			num: 0,
			expandedRowKeys: [],
			formInline: {
				time: [],
			},
			innerColumns: [
				{ title: '检测内容', dataIndex: 'content', width: '22.5%', key: 'content', scopedSlots: { customRender: 'content' } },
				{
					title: '内容数',
					dataIndex: 'contentUnit',
					key: 'contentUnit',
					width: '19.5%',
					customRender(text, record) {
						return record.contentType == 1 ? '1张' : record.contentType == 2 ? text + '秒' : record.contentType == 3 ? text + '秒' : record.contentType == 4 ? text + '字' : text || '--';
					},
				},
				{
					title: '实付金额',
					dataIndex: 'payAmount',
					key: 'payAmount',
					width: '19.5%',
					customRender(text, record) {
						return text || text == 0 ? '￥' + text : '--';
					},
				},
				{
					title: '检测状态',
					dataIndex: 'state',
					key: 'state',
					width: '19%',
					customRender(text, record) {
						return text == 0 ? '待检测' : text == 1 ? '检测完成' : text == 2 ? '检测中' : text == 3 ? '检测失败' : '--';
					},
				},
				{ title: '操作', key: 'operation', width: '19.5%', scopedSlots: { customRender: 'operation' } },
			],
			innerData: [],
			columns: [
				{
					title: '订单编号',
					dataIndex: 'tradeId',
					key: 'tradeId',
					width: '22.5%',
					customRender(text, record, index) {
						return index + 1 + '、' + text;
					},
				},
				{
					title: '提交时间',
					dataIndex: 'addTime',
					key: 'addTime',
					width: '19.5%',
					customRender(text) {
						return _this.$moment(text).format('YYYY-MM-DD HH:mm:ss');
					},
				},
				{
					title: '支付方式',
					dataIndex: 'payType',
					key: 'payType',
					width: '19.5%',
					customRender(text) {
						return text == 1 ? '支付宝支付' : text == 2 ? '微信支付' : text == 3 ? '会员免费' : '--';
					},
				},
				{
					title: '检测方式',
					dataIndex: 'type',
					key: 'type',
					width: '19%',
					customRender(text) {
						return text == 1 ? '智能检测' : text == 2 ? '人工审读' : '专家审读';
					},
				},
				{
					title: '支付状态',
					dataIndex: 'payState',
					key: 'payState',
					width: '19.5%',
					scopedSlots: { customRender: 'payState' },
				},
			],
			data: [],
			pagination: {
				total: 0,
				current: 0,
				defaultPageSize: 10,
				showTotal: (total, range) => `当前第 ${range[0]}-${range[1]} 条 共计 ${total} 条`,
				showSizeChanger: true,
				pageSizeOptions: ['5', '10', '15', '20'],
				onShowSizeChange: (current, pageSize) => {
					this.pageSize = pageSize;
				},
			},
			payData: {},
			payModalVisible: false,
			tradeId: null, // 主订单号
			tableLoading: true,
			pageSize: 10,
			userInfo: {},
			tableHeight: 750,
		};
	},
	created() {
		this.host = process.env.VUE_APP_RESOURCES_HOST;
		this.userInfo = this.$store.getters.userInfo;
		this.handleSubmit('placeholder', 0, 10);

		if (document.body.clientWidth <= 1366) {
			this.tableHeight = 390;
		} else if (document.body.clientWidth > 1366 && document.body.clientWidth <= 1440) {
			this.tableHeight = 500;
		} else if (document.body.clientWidth > 1440 && document.body.clientWidth <= 1600) {
			this.tableHeight = 530;
		} else if (document.body.clientWidth > 1600) {
			this.tableHeight = 650;
		}
	},
	computed: {
		coverImgUrl() {
			return data => {
				if (data.indexOf('http') != '-1') {
					return data;
				} else {
					// return 'https://resources.xbov.cn' + data;
					return this.host + data;
				}
			};
		},
	},
	methods: {
		cancelOrder(orderData) {
			let _this = this;
			this.$http
				.get('/monitortrade/cancellationOfOrder?TradeId=' + orderData.tradeId)
				.then(res => {
					_this.$message.success('操作成功！');
					_this.handleSubmit('placeholder', this.pagination.current, this.pageSize ? this.pageSize : 10);
				})
				.catch(err => {
					console.log('取消订单失败', err);
				});
		},
		closePayModal() {
			this.payModalVisible = false;
			this.handleSubmit('placeholder', this.pagination.current, this.pageSize ? this.pageSize : 10);
		},
		pay(e) {
			this.tradeId = e.tradeId;
			this.payData.payAmount = e.payAmount ? e.payAmount : 0;
			this.payModalVisible = true;
		},
		tableChange(e) {
			this.handleSubmit('placeholder', e.current - 1, e.pageSize);
			this.pagination.current = e.current;
		},
		handleSubmit(e, pageNum, pageSize) {
			let that = this;
			//查询
			let time = this.formInline.time;
			let params = {
				pageNum: pageNum || pageNum == 0 ? pageNum : 0,
				pageSize: pageSize ? pageSize : that.pageSize,
				buyUserCode: this.userInfo.userCode,
			};
			if (time && time.length > 0) {
				let minAddTime = that.$moment(time[0]).format('YYYY-MM-DD') + 'T00:00:00';
				let maxAddTime = that.$moment(time[1]).format('YYYY-MM-DD') + 'T23:59:59';
				params.minAddTime = minAddTime;
				params.maxAddTime = maxAddTime;
			}
			if (this.formInline.payType) {
				params.payType = Number(this.formInline.payType);
			}
			if (this.formInline.payState) {
				params.payState = this.formInline.payState;
			}
			if (this.formInline.type) {
				params.type = this.formInline.type;
			}
			that.tableLoading = true;
			this.$http
				.post('/monitortrade/allTradeList', params)
				.then(res => {
					if (res.rescode == 200) {
						that.tableLoading = false;
						this.data = res.data.dataList;
						let list = [];
						res.data.dataList.map(item => {
							list.push(item.tradeId);
						});
						this.expandedRowKeys = list;
						this.pagination.total = res.data.totalCount;
					} else {
						that.tableLoading = false;
						this.$message.warning(res.msg);
					}
				})
				.catch(error => {
					that.tableLoading = false;
					console.log(error);
				});
		},
		reset() {
			this.$refs.ruleForm.resetFields();
			this.handleSubmit('placeholder', 0, this.pageSize);
			this.pagination.current = 0;
		},
		orderDetail(data) {
			this.$router.push({
				name: 'OrderDetail',
				query: { tradeId: data.tradeId },
			});
		},
		closeMediaModal() {
			this.mediaVisible = false;
			this.mediaSrc = null;
			this.mediaType = null;
		},
		closeTextModal() {
			this.textVisible = false;
		},
		preview(data) {
			if (data.contentType == 1) {
				this.$refs.viewer.show([
					{
						thumbnail: data.content,
						source: data.content,
					},
				]);
			} else if (data.contentType == 2) {
				this.mediaSrc = data.content;
				this.mediaVisible = true;
				this.mediaType = 2;
			} else if (data.contentType == 3) {
				this.mediaSrc = data.content;
				this.mediaVisible = true;
				this.mediaType = 3;
			} else if (data.contentType == 4) {
				this.contentText = data.content;
				this.textVisible = true;
			}
		},
	},
};
</script>
<style lang="less" scoped>
.pageContainer {
	box-sizing: border-box;
	margin-left: 9px;
	margin-top: 9px;
	margin-bottom: 8px;
	height: 98%;
	overflow-y: auto;
	background: #fff;
	.checkOrder {
		padding: 6px;
		height: 100%;
		background: #fff;
	}
	/deep/.ant-table-row-expand-icon {
		display: none;
	}
	/deep/.ant-table-row-expand-icon-cell {
		border: 0;
	}
	.button {
		margin-top: 3px;
		margin-right: 20px;
		color: #999999;
	}
	.search {
		float: right;
	}
	.table {
		.innerTable {
			/deep/ .ant-table-row-level-0 {
				background: unset;
				// td {
				//     display: flex;
				//     align-items: center;
				//     justify-content: center;
				// }
			}
		}
		/deep/ .ant-table-row-level-0 {
			background: #edf3ff;
		}
		.img {
			width: 70px;
			height: 40px;
		}
		.icon {
			font-size: 25px;
			cursor: pointer; //鼠标变小手
		}
		.video {
			position: relative;
			.img {
				width: 70px;
				height: 40px;
			}
			.icon {
				font-size: 25px;
				cursor: pointer; //鼠标变小手
				position: absolute;
				top: -16px;
				left: 22px;
				color: #fff;
			}
		}
	}
	.marginR {
		margin-right: 20px;
	}
	.orange {
		background-color: #ff9e02;
		color: #fff;
	}
	.gray {
		background-color: #cccccc;
		color: #fff;
	}
	.tableFooter {
		.left {
			margin-top: 20px;
		}
		.floatR {
			float: right;
		}
	}

	.blue {
		color: #40a9ff;
	}
	.red {
		color: red;
	}
}
</style>
